import * as React from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput, NumberInput, TextInput, required } from 'react-admin';

export const PaymentCreate = (props) => {
    return (
        <Create {...props} title="Создание платежа">
            <SimpleForm>
                <ReferenceInput label="Пользователь" source="UserId" reference="users" validate={[required()]}>
                    <SelectInput optionText="email" />
                </ReferenceInput>
                <SelectInput source="paymentMethod" label="Способ оплаты" choices={[{ id: 'cardToCard', name: 'Перевод на карту'}, { id: 'bankAccount', name: 'Перевод на расчетный счет' }]} validate={[required()]} />
                <NumberInput source="amount" label="Сумма" min={1} validate={[required()]} />
                <TextInput source="comment" label="Комментарий" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};
