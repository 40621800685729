import * as React from "react";
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import { UserList, UserCreate, UserEdit } from './Users';
import { PaymentCreate, PaymentList } from './Payments';
import Login from './Login';
import authProvider from './authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import CustomLayout from './CustomLayout';
import { Stats } from './Stats';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');


const App = () => (
    <Admin i18nProvider={i18nProvider} loginPage={Login} layout={CustomLayout} dataProvider={dataProvider} authProvider={authProvider}>
        { permissions => [
            permissions && permissions === 'admin' ? <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit} options={{ label: "Пользователи" }} /> : null,
            <Resource name="payments" list={PaymentList} create={permissions && permissions === 'admin' ? PaymentCreate : null} options={{ label: "Платежи" }} />,
            <Resource name="stats" list={Stats} options={{ label: 'Статистика' }} />
        ]}
    </Admin>
);

export default App;