import * as React from "react";
import { List, Datagrid, TextField, EmailField, NumberField, FunctionField, EditButton, DeleteButton } from 'react-admin';

export const UserList = props => (
    <List {...props} title="Пользователи" bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <EmailField source="email" />
            <FunctionField render={record => `${record.lastName} ${record.firstName} ${record.patronymic ? ' ' + record.patronymic : ''}`} source="lastName" label="ФИО" />
            <TextField source="phone" label="Телефон" />
            <NumberField source="balance" label="Баланс" locales="ru-RU" options={{ style: 'currency', currency: 'RUB' }} />
            <FunctionField source="isBlocked" render={({ isBlocked }) => isBlocked ? 'Да' : 'Нет'} label="Заблокирован" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);