import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { useQueryWithStore } from 'react-admin';

const ShowBalance = () => {
    const { loaded, data } = useQueryWithStore({ type: 'getMe', resource: '', payload: {} });
    if (!loaded) return null;

    return (
        <Typography
            variant="h6"
            color="inherit">
            <span>{data.balance.toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})}</span>
        </Typography>
    );
};

export default ShowBalance;