import * as React from 'react';
import { Datagrid, DateInput, EmailField, Filter, FunctionField, List, ListContextProvider, NumberField, ReferenceInput, SelectInput, TextField } from 'react-admin';

const StatsFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        {permissions && permissions === 'admin' ? (
            <ReferenceInput source="userId" reference="users" label="Пользователь" alwaysOn>
                <SelectInput optionText="email" />
            </ReferenceInput>
        ) : null}
        <DateInput source="startDate" label="Начало периода" alwaysOn />
        <DateInput source="endDate" label="Конец периода" alwaysOn />
    </Filter>
);

const StatsDetails = ({ id, record, resource }) => {
    const services = {
        ACCIDENTS: 'Информация о ДТП',
        INSPECTIONS: 'Информация о ТО',
        POLICIES: 'Полисы ОСАГО',
        VEHICLEINFO: 'Информация об автомобиле',
        RSA: 'Полисы ОСАГО (расширенный)',
        WANTED: 'Информация о розысках',
        RESTRICTIONS: 'Информация об ограничениях',
        PLEDGES: 'Информация о залогах',
        REPAIRS: 'Информация о ремонтах',
        TAXI: 'Информация о разрешениях на работу в качестве такси',
        EGARANT: 'еГарант',
        EGARANTWITHTEMPPHONE: 'еГарант с временным номером телефона (тариф 1)',
        EGARANTWITHTEMPPHONE2: 'еГарант с временным номером телефона (тариф 2)',
    };
    const data = {};
    record.services.forEach((item, index) => {
        data[index] = { id: index, service: services[item.service], count: item.count, amount: item.amount };
    });

    const options = {
        data,
        ids: Object.keys(data),
        basePath: '/stats',
        currentSort: {},
        loaded: true,
        resource: 'stats',
        selectedIds: [],
        total: Object.keys(data).length,
    };

    return (
        <div>
            <ListContextProvider value={options}>
                <Datagrid>
                    <FunctionField render={record => ' '} />
                    <TextField source="service" label="Сервис" sortable={false} />
                    <NumberField source="count" label="Количество" sortable={false} />
                    <NumberField source="amount" label="Сумма" locales="ru-RU" options={{ style: 'currency', currency: 'RUB' }} sortable={false} />
                </Datagrid>
            </ListContextProvider>
        </div>
    );
};

export const Stats = ({ permissions, ...props }) => {
    return (
        <List {...props} title="Статистика" pagination={false} bulkActionButtons={false} filters={<StatsFilter permissions={permissions} {...props} />}>
            <Datagrid rowClick="expand" expand={<StatsDetails />}>
                {permissions && permissions === 'admin' ? (
                    <EmailField source="email" sortable={false} />
                ) : null}
                <NumberField source="count" label="Количество" sortable={false} />
                <NumberField source="amount" label="Сумма" locales="ru-RU" options={{ style: 'currency', currency: 'RUB' }} sortable={false} />
            </Datagrid>
        </List>
    );
};