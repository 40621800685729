import *as React from "react";
import { List, Datagrid, DateField, TextField, NumberField, DeleteButton, SelectField } from "react-admin";

export const PaymentList = ({permissions, ...props}) => (
    <List {...props} title="Платежи">
        <Datagrid>
            <TextField source="id" />
            <DateField source="createdAt" label="Дата" options={{ year: 'numeric', month: 'numeric', day: 'numeric' }} />
            <TextField source="User.email" label="Пользователь" />
            <SelectField source="paymentMethod" label="Способ оплаты" choices={[{ id: 'cardToCard', name: 'Перевод с карты на карту' }, { id: 'bankAccount', name: 'Перевод на рассчетный счет' }]} />
            <NumberField source="amount" label="Сумма" locales="ru-RU" options={{ style: 'currency', currency: 'RUB' }} />
            <TextField source="comment" label="Комментарий" />
            {permissions && permissions === 'admin' ? <DeleteButton /> : null}
        </Datagrid>
    </List>
);