import * as React from "react";
import { Edit, FormDataConsumer, useNotify, useRedirect } from 'react-admin';
import { SimpleForm, TextInput, PasswordInput, NumberInput, SelectInput, email, required, minLength, maxLength, regex, ArrayInput, SimpleFormIterator, CheckboxGroupInput, BooleanInput } from 'react-admin';

const validateEmail = [required(), email()];
const validatePassword = [minLength(6)];
const validatePhone = [required(), maxLength(10), regex(/^[0-9]{10}$/, 'Должны быть только цифры')];

export const UserEdit = props => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify('Пользователь сохранен', 'info', {}, true);
        redirect('list', props.basePath);
    };

    return (<Edit {...props} title="Редактирование пользователя" onSuccess={onSuccess}>
        <SimpleForm>
            <TextInput source="email" validate={validateEmail} />
            <PasswordInput source="password" label="Пароль" validate={validatePassword} />
            <TextInput source="lastName" label="Фамилия" validate={[required()]} />
            <TextInput source="firstName" label="Имя" validate={[required()]} />
            <TextInput source="patronymic" label="Отчество" />
            <TextInput source="phone" label="Телефон" validate={validatePhone} />
            <BooleanInput source="isBlocked" label="Пользователь заблокирован" />
            <BooleanInput source="allowDebt" label="Разрешить работу в долг" />
            <FormDataConsumer>
                {({ formData, ...rest }) => formData.allowDebt && <NumberInput source="debtLimit" label="Лимит долга" />}
            </FormDataConsumer>
            <CheckboxGroupInput label="Роли" source="roles"
                choices={[
                    { id: 1, name: 'admin' },
                    { id: 2, name: 'user' }
                ]}
                optionText={record => record.id === 1 ? 'Администратор' : 'Пользователь'}
                validate={[required()]}
            />
            <ArrayInput source="services" label="Сервисы" validate={[required()]}>
                <SimpleFormIterator>
                    <SelectInput source="service" label="Сервис"
                        choices={[
                            { id: 'VEHICLEINFO', name: 'Информация об автомобиле' },
                            { id: 'POLICIES', name: 'Полисы ОСАГО' },
                            { id: 'RSA', name: 'Полисы (расширенный)' },
                            { id: 'INSPECTIONS', name: 'Информация о ТО' },
                            { id: 'ACCIDENTS', name: 'Информация о ДТП' },
                            { id: 'WANTED', name: 'Информация о розысках' },
                            { id: 'RESTRICTIONS', name: 'Информация об ограничениях' },
                            { id: 'PLEDGES', name: 'Информация о залогах' },
                            { id: 'REPAIRS', name: 'Информация о ремонтах' },
                            { id: 'TAXI', name: 'Информация о разрешениях на работу в качестве такси' },
                            { id: 'EGARANT', name: 'еГарант' },
                            { id: 'EGARANTWITHTEMPPHONE', name: 'еГарант с временным номером телефона (тариф 1)' },
                            { id: 'EGARANTWITHTEMPPHONE2', name: 'еГарант с временным номером телефона (тариф 2)' },
                        ]} />
                    <NumberInput source="cost" label="Стоимость" min={0} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
    );
};