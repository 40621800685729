import decodeJwt from 'jwt-decode';

const authProvider = {
    login: ({
        email,
        password
    }) => {
        const request = new Request('/api/authenticate', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = decodeJwt(token);
                localStorage.setItem('token', token);
                localStorage.setItem('permissions', decodedToken.permissions);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    checkAuth: params => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    checkError: error => [401, 403].includes(error.status) ? Promise.reject() : Promise.resolve(),
    getPermissions: params => {
        const permissions = localStorage.getItem('permissions');
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    },
    getIdentity: () => Promise.resolve(),
};

export default authProvider;